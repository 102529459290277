@charset "UTF-8";
@import "ap_preaset";

/*************************
*** Search History 공통 ***
**************************/

.sectionWrapper.history .removeButton{
  position: absolute;
  display: block;
  margin: toRem(4);
  height: toRem(16);
  width: toRem(16);
  right: toRem(24);
  top: toRem(24);
}

.sectionWrapper.history .removeButton .icon{
  width: 100%;
  height: 100%;
  background-size: contain;
  opacity: 0.5;
  margin-left: 0;
}

.sectionWrapper.history .sectionDesc{
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14);
  line-height: 1.4em;
  color: $ap-text-gray-1;
  padding-bottom: 0.2rem;
  word-break: keep-all;
}

.sectionWrapper.history .titleWrap{
  justify-content: flex-start;
  gap: toRem(16);
  align-items: center;
}

@include rwd('xl'){
  .comPdL {
    padding-left: toRem(40);
  }
}
@include rwd('md'){
  .comPdL {
    padding-left: toRem(25);
  }
}

 .historyWrap{
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: toRem(20);
}


.historyWrap .list{
  position: relative;
  padding: toRem(24);
  background-color: #F7F7F7;
  border-radius: toRem(12);
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: toRem(4);
}

.historyWrap .list .cityLabelContainer{
  font-weight: 700;
  font-size: toRem(16);
  line-height: 1.5em;
  white-space: pre;
  display: flex;
  max-width: calc(100% - 1.8rem);
  width:fit-content;
  flex-wrap: nowrap;
  gap: toRem(2);
}
.historyWrap .list .cityLabel{
  width:fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.historyWrap .list .cityLabelContainer span{
  font-weight: 400;
}
.historyWrap .list .reservationInfo{
  font-weight: 400;
  font-size: toRem(13);
  line-height: toRem(20);
  color: $ap-text-gray-1;
  display: flex;
  flex-wrap: wrap;
}

@include rwd('lg'){
  .historyWrap{
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
 .historyWrap .list{
    padding: toRem(20);
    gap: toRem(2);
    border-radius: toRem(12);
  }
  .historyWrap .list .cityLabelContainer{
    font-size: toRem(15);
  }
  .historyWrap .list .cityLabel .label{
      max-width: fit-content;
  }
  .historyWrap .list:nth-child(3), .historyWrap .list:nth-child(4){
    display: none;
  }
  .sectionWrapper.history .removeButton{
     width: toRem(11);
     height: toRem(11);
   }
}

@include rwd('md'){
  .historyWrap{
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: toRem(12);
  }
}


/**************************
*** Home Search History ***
***************************/


.mainSecHistory{
  overflow-x: auto;
}
